import Portal from 'components/portals/Portal'
import SearchTrip from 'components/SearchTrip/SearchTrip'

const SearchTripPortal = ({
  TM,
  updateFiltersAndFetch,
  areSearchTripFiltersApplied,
  setAreSearchTripFiltersApplied,
  updateTitlesEnabled,
}) => (
  <Portal
    elementId="portal-search-trips"
    component={
      <SearchTrip
        TM={TM}
        updateFiltersAndFetch={updateFiltersAndFetch}
        areSearchTripFiltersApplied={areSearchTripFiltersApplied}
        setAreSearchTripFiltersApplied={setAreSearchTripFiltersApplied}
        updateTitlesEnabled={updateTitlesEnabled}
      />
    }
  />
)

export default SearchTripPortal
