import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'

import { sendUserPreferences } from 'api/api'
import { LOCAL_STORAGE_KEYS as KEYS, TRANSPORT, STATUS } from 'shared/constants'
import { getLocalStorageData, setLocalStorageData } from 'shared/utils'

export const UserDataContext = createContext(null)

export const UserDataProvider = ({ children }) => {
  const [userData, setUserData] = useState(null)

  const reloadUser = useCallback(() => {
    const udata = getLocalStorageData(KEYS.USER_DATA)
    if (udata) {
      setUserData({ ...udata })
    }
  }, [])

  useEffect(() => {
    reloadUser()
  }, [reloadUser])

  const sendUserPrefs = useCallback(
    async (preferences) => {
      const userId = userData?.id || userData?.subscriber_id
      // aktualnie używamy "id", "subscriber_id" jest dla zachowania kompatybilności wstecznej
      if (!userId) {
        // console.debug('sendUserPrefs - no user')
        return
      }

      return sendUserPreferences({
        id: userId,
        token: userData.token,
        status: userData.status || STATUS.INSTALLED,
        transport: userData.transport || TRANSPORT.DEFAULT,
        ...preferences,
      }).then((res) => {
        if (!!userData?.email) {
          // w starej wersji email był zapisany pod kluczem USER_DATA
          // jeśli tak jest to zapisz pod kluczem USER_EMAIL
          setLocalStorageData(KEYS.USER_EMAIL, userData.email)
        }

        setLocalStorageData(KEYS.USER_DATA, res.data)
      })
    },
    [userData],
  )

  return (
    <UserDataContext.Provider
      value={{
        userData: userData,
        setUserData: setUserData,
        userExists: !!userData,
        sendUserPrefs: sendUserPrefs,
        reloadUser: reloadUser,
      }}
    >
      {children}
    </UserDataContext.Provider>
  )
}

export const useUserData = () => {
  const context = useContext(UserDataContext)

  return context
}
