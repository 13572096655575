const ShareIcon2 = ({ svgPathClass, ...rest }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#383837"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      className={svgPathClass}
      d="M8.137,11.631a.908.908,0,0,1-.17.038A3.016,3.016,0,0,1,8,12a2.962,2.962,0,0,1-.033.328.949.949,0,0,1,.173.042L16.9,16.485a.991.991,0,0,1,.242.18A2.969,2.969,0,0,1,19,16a3.037,3.037,0,1,1-2.911,2.306c-.014-.006-.029,0-.044-.01L7.289,14.181a.953.953,0,0,1-.134-.1,3,3,0,1,1,0-4.162,1,1,0,0,1,.133-.1L16.045,5.7c.014-.007.029,0,.044-.011A2.93,2.93,0,0,1,16,5a3,3,0,1,1,3,3,2.969,2.969,0,0,1-1.862-.665,1.03,1.03,0,0,1-.242.18Z"
    />
  </svg>
)

export default ShareIcon2
