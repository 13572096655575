import './checkboxField.css'
import CheckedIcon from 'components/UI/icons/CheckedIcon'

const CheckboxField = ({
  name,
  checked,
  semiChecked,
  disabled,
  onClick,
  children,
}) => {
  const handleClick = () => {
    if (!disabled) onClick(name, !checked)
  }

  let checkboxClassName = 'checkbox__checkbox'
  if (disabled) checkboxClassName += ' chbx_disabled'
  if (checked) {
    checkboxClassName += ' chbx_checked'
    if (semiChecked) checkboxClassName += ' chbx_semichecked'
  }

  return (
    <div className="checkbox__field" onClick={handleClick}>
      <input type="checkbox" disabled={disabled} />
      <div className={checkboxClassName}>{checked && <CheckedIcon />}</div>
      {children}
    </div>
  )
}
export default CheckboxField
