const CloseIcon = ({ svgPathClass, ...rest }) => (
  <svg
    width="24"
    height="24"
    viewBox="7 7 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      className={svgPathClass}
      d="M8.46445 15.5355L15.5355 8.46448"
      stroke="#93b5b0"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      className={svgPathClass}
      d="M8.46446 8.46445L15.5355 15.5355"
      stroke="#93b5b0"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export default CloseIcon
