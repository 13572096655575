import { createPortal } from 'react-dom'

import './modal.css'

const Modal = ({ children }) => {
  const parent = document.getElementById('modal-root')

  return createPortal(<div className="modal">{children}</div>, parent)
}
export default Modal
