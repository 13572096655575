import './searchTripButtons.css'

import ShareIcon2 from 'components/UI/icons/ShareIcon2'

const ShareButton = ({ onClick, pulsing }) => {
  let classNames = 'search_trip__share_icon icon_hover_effect'
  if (pulsing) classNames += ' pulse'

  return (
    <ShareIcon2
      className={classNames}
      svgPathClass="stt_icon_color"
      onClick={onClick}
    />
  )
}

export default ShareButton
