import { decode } from 'html-entities'

import { formatPrice } from 'shared/utils'
import { shorten } from 'shared/constants'

import './tableRow.css'
import Stars from 'components/UI/Stars/Stars'
import Badge from 'components/UI/Badge/Badge'

const formatDate = (date) => {
  // 2023-02-06 00:00:00 => 06.02
  const x = date.split(' ')[0].split('-')
  return `${x[2]}.${x[1]}`
}

const getPriceCut = (price, history) => {
  const previousPrice = history.length !== 0 && history[0].pln
  const priceCut = previousPrice ? price - previousPrice : null

  return priceCut < 0 ? priceCut : null
}

const getMinPrice = (price, history) => {
  if (history.length === 0) return null

  return history.reduce((min, item) => (item.pln < min ? item.pln : min), price)
}

const PriceCut = ({ discount, history }) => {
  let { date, pln } = { ...history[0] }
  date = String(date).substring(0, String(date).length - 3)

  return (
    <div
      className="item i_price_cut"
      title={`Cena ${pln} zł obniżona o ${discount} zł (${date})`}
    >
      {formatPrice(discount, true)}
    </div>
  )
}

const MinPrice = ({ minPrice }) => (
  <div className="item i_min_price">{`(${formatPrice(minPrice)} zł)`}</div>
)

const TableRow = ({ trip }) => {
  const hotel = JSON.parse(trip.hotel)
  const priceCut = getPriceCut(trip.pln, trip.history)
  const minPrice = getMinPrice(trip.pln, trip.history)
  const showMinorPrices = priceCut && minPrice

  let priceClass = 'item i_price'
  if (!showMinorPrices) priceClass += ' i_price_center'

  const date = `${formatDate(trip.date)} na ${trip.days} dni`
  const dateMobile = `${formatDate(trip.date)}/${trip.days}dni`

  return (
    <li className="table_row__container">
      <a href={trip.link} target="_blank" rel="noreferrer">
        <div className="item i_country">{trip.country}</div>
        <div className="item i_region">{decode(trip.city)}</div>
        <div className="item i_hotel">{decode(hotel.name)}</div>
        <div className="item i_image">
          <img src={hotel.img} alt={hotel.name} />
        </div>
        <div className="item i_date desktop">
          <span>{date}</span>
        </div>
        <div className="item i_date mobile">
          <span>{dateMobile}</span>
        </div>
        <div className="item i_boarding desktop">{trip.board}</div>
        <div className="item i_boarding mobile">{shorten[trip.board]}</div>
        <div className="item i_stars">
          <Stars count={trip.stars} />
        </div>
        <div className={priceClass}>{formatPrice(trip.pln)}</div>
        {showMinorPrices && (
          <PriceCut discount={priceCut} history={trip.history} />
        )}
        {showMinorPrices && <MinPrice minPrice={minPrice} />}
        <div className="item i_source desktop">
          <Badge icon="source">{trip.airport}</Badge>
        </div>
        <div className="item i_source mobile">
          <Badge>{shorten[trip.airport]}</Badge>
        </div>
      </a>
    </li>
  )
}

export default TableRow
