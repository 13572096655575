import Portal from 'components/portals/Portal'
import ExtraPages from 'components/ExtraPages/ExtraPages'

const ExtraPagesPortal = ({ pages, isLoading, loadMore, loadAnotherPage }) => (
  <Portal
    elementId="portal-extra-pages"
    component={
      <ExtraPages
        pages={pages}
        isLoading={isLoading}
        loadMore={loadMore}
        loadAnotherPage={loadAnotherPage}
      />
    }
  />
)

export default ExtraPagesPortal
