import { useEffect } from 'react'

import { useUserData } from 'hooks/useUserData'

const SendUserPrefs = (prefs) => {
  const { userExists, sendUserPrefs } = useUserData()

  useEffect(() => {
    if (userExists) {
      try {
        sendUserPrefs(prefs)
      } catch (error) {}
    }
    // eslint-disable-next-line
  }, [userExists, sendUserPrefs])

  return null
}

export default SendUserPrefs
