import './tripsTable.css'
import TableRow from 'components/TripsTable/TableRow/TableRow'
import Spinner from 'components/UI/Spinner/Spinner'

const TripsTable = ({ tripsArr, isLoading }) => {
  if (!tripsArr?.length > 0) return

  return (
    <div className="trips_table__container">
      <ul className={isLoading ? 'blur' : null}>
        {tripsArr.map((i) => (
          <TableRow key={i.id} trip={i} />
        ))}
      </ul>
      {isLoading && (
        <div className="trips_table__modal">
          <Spinner />
        </div>
      )}
    </div>
  )
}
export default TripsTable
