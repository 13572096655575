import CheckboxField from 'components/UI/CheckboxField/CheckboxField'

const ItemCheckbox = ({ data, onClick, addLi, ...props }) => {
  const CheckboxItem = () => (
    <CheckboxField
      name={data.textContent}
      checked={data.selected}
      onClick={onClick}
    >
      <label>{data.textDisplay || data.textContent}</label>
    </CheckboxField>
  )

  return addLi ? (
    <li {...props}>
      <CheckboxItem />
    </li>
  ) : (
    <CheckboxItem />
  )
}

export default ItemCheckbox
