import AlertsPanelLazy from 'components/Alerts/AlertsPanel/AlertsPanelLazy'
import ModalBox from 'components/Layout/ModalBox/ModalBox'

const Alerts = ({ iconId, onClose }) => (
  <ModalBox iconId={iconId} onClose={onClose}>
    <AlertsPanelLazy onClose={onClose} />
  </ModalBox>
)

export default Alerts
