import { useState } from 'react'
import styled from 'styled-components'
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,
  InstapaperShareButton,
  InstapaperIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  VKShareButton,
  VKIcon,
} from 'react-share'

import './shareButtons.css'
import ModalBox from 'components/Layout/ModalBox/ModalBox'
import Button from 'components/UI/buttons/Button'

const size = 30,
  r = 11

const Hr = styled.div`
  height: 8px;
  width: calc(100% - 0.75rem);
  margin: 0 0 8px 0.375rem;
  border-bottom: 1px solid var(--color-gray-4);
`

const CopyUrlContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 36px 5px 5px;
  padding: 5px 0 0;
  @media (max-width: 560px) {
    max-width: 350px;
    margin-right: 5px;
  }
  @media (max-width: 390px) {
    max-width: 100%;
  }
`

const CopyUrlButton = styled(Button)`
  width: 90px;
  min-width: 90px;
`

const Input = styled.input`
  font-size: 1.1rem;
  color: var(--color-gray-3);
  padding: 4px 6px;
  margin: 0 10px 0 0;
  background: var(--color-white);
  border: 1px solid var(--color-gray-1);
  border-radius: var(--radius-sm);
  outline: none;
  flex-grow: 2;
`

const ShareButtons = ({ iconId, onClose, title, url, offsetRight }) => {
  const [copied, setCopied] = useState(false)

  if (!title) title = document.querySelector('.deal__title')?.textContent ?? ''
  if (!url) url = window.location.href

  const buttons = [
    {
      component: (
        <EmailShareButton url={url} subject={title}>
          <EmailIcon size={size} borderRadius={r} />
        </EmailShareButton>
      ),
      tooltip: 'przez email',
    },
    {
      component: (
        <FacebookShareButton url={url} quote={title} hashtag="#lastminuter">
          <FacebookIcon size={size} borderRadius={r} />
        </FacebookShareButton>
      ),
      tooltip: 'na Facebooku',
    },
    {
      component: (
        <TwitterShareButton url={url} title={title}>
          <XIcon size={size} borderRadius={r} />
        </TwitterShareButton>
      ),
      tooltip: 'na X',
    },
    {
      component: (
        <WhatsappShareButton url={url} title={title}>
          <WhatsappIcon size={size} borderRadius={r} />
        </WhatsappShareButton>
      ),
      tooltip: 'na WhatsApp',
    },
    {
      component: (
        <InstapaperShareButton url={url} title={title}>
          <InstapaperIcon size={size} borderRadius={r} />
        </InstapaperShareButton>
      ),
      tooltip: 'na Instapaper',
    },
    {
      component: (
        <LinkedinShareButton
          url={url}
          title={title}
          source={window.location.origin}
        >
          <LinkedinIcon size={size} borderRadius={r} />
        </LinkedinShareButton>
      ),
      tooltip: 'na Linkedin',
    },
    {
      component: (
        <RedditShareButton url={url} title={title}>
          <RedditIcon size={size} borderRadius={r} />
        </RedditShareButton>
      ),
      tooltip: 'na Reddit',
    },
    {
      component: (
        <TelegramShareButton url={url} title={title}>
          <TelegramIcon size={size} borderRadius={r} />
        </TelegramShareButton>
      ),
      tooltip: 'na Telegram',
    },
    {
      component: (
        <VKShareButton url={url} title={title}>
          <VKIcon size={size} borderRadius={r} />
        </VKShareButton>
      ),
      tooltip: 'na VK',
    },
  ]

  return (
    <ModalBox iconId={iconId} onClose={onClose} offsetRight={offsetRight}>
      <div className="share_buttons">
        {buttons.map((button) => (
          <div
            key={button.tooltip}
            className="share_btn"
            title={`Udostępnij ${button.tooltip}`}
          >
            {button.component}
          </div>
        ))}
      </div>
      <Hr />
      <CopyUrlContainer>
        <Input value={url} onChange={null} disabled />
        <CopyUrlButton
          disabled={copied}
          onClick={() => {
            navigator.clipboard.writeText(url)
            setCopied(true)
          }}
        >
          {copied ? 'Skopiowano' : 'Kopiuj link'}
        </CopyUrlButton>
      </CopyUrlContainer>
    </ModalBox>
  )
}

export default ShareButtons
