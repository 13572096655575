import { useState } from 'react'

import './destinationOptions.css'
import OptionsGroup from 'components/UI/OptionsGroup/OptionsGroup'
import ItemCheckbox from 'components/SearchTrip/ItemsComponents/ItemCheckbox/ItemCheckbox'
import DropdownIcon from 'components/UI/icons/DropdownIcon'
import CheckboxField from 'components/UI/CheckboxField/CheckboxField'

const DestinationOptions = ({ options, onClickOption }) => {
  const [expandedDestinations, setExpandedDestinations] = useState({})

  const onToggleRegions = (e, destination) => {
    e.stopPropagation()

    if (expandedDestinations[destination]) {
      const newExpandedDestinations = { ...expandedDestinations }
      delete newExpandedDestinations[destination]
      setExpandedDestinations(newExpandedDestinations)
    } else {
      setExpandedDestinations({ ...expandedDestinations, [destination]: true })
    }
  }

  const ToggleButton = ({ destination }) => (
    <div
      className="destination_item__toggle"
      onClick={(e) => {
        onToggleRegions(e, destination)
      }}
    >
      <DropdownIcon
        style={{
          transform: `rotate(${
            expandedDestinations[destination] ? 180 : 0
          }deg)`,
        }}
        onClick={(e) => {
          onToggleRegions(e, destination)
        }}
      />
    </div>
  )

  const RegionItem = ({ regionData }) => (
    <ItemCheckbox data={regionData} onClick={onClickOption} addLi />
  )

  const DestinationItem = ({ destData }) => {
    let selectedRegionsCount = 0
    let allRegionsSelected = false
    if (destData.selected) {
      selectedRegionsCount = destData.items.filter((i) => i.selected).length
      allRegionsSelected = selectedRegionsCount === destData.items.length
    }
    const showAsterisk = destData.selected && selectedRegionsCount > 0

    return (
      <li className="destination_item">
        <CheckboxField
          name={destData.textContent}
          checked={destData.selected}
          semiChecked={selectedRegionsCount > 0 && !allRegionsSelected}
          onClick={onClickOption}
        >
          <label>{destData.textDisplay || destData.textContent}</label>
          {showAsterisk && (
            <span className="destination_item__asterisk">*</span>
          )}
          <ToggleButton destination={destData.textContent} />
        </CheckboxField>
        {expandedDestinations[destData.textContent] && (
          <ul className="options_group__subgroup">
            {destData['items'].map((i) => (
              <RegionItem key={i.textContent} regionData={i} />
            ))}
          </ul>
        )}
      </li>
    )
  }

  return (
    <>
      {options.map((group) => (
        <OptionsGroup key={group.textContent} name={group.textContent}>
          <ul className="destinations_group">
            {group['items'].map((i) => (
              <DestinationItem key={i.textContent} destData={i} />
            ))}
          </ul>
        </OptionsGroup>
      ))}
    </>
  )
}

export default DestinationOptions
