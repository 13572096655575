import { icons } from './BadgeIcons'
import './badge.css'

const Badge = ({ icon, inactive, children, ...rest }) => {
  let Icon = () => null
  if (icon && icon in icons) {
    Icon = () => (
      <img
        className="badge__icon"
        src={icons[icon].component}
        alt={icons[icon].alt}
      />
    )
  }

  let className = 'badge'
  if (inactive) className += ' badge_inactive'

  return (
    <span className={className} {...rest}>
      <Icon />
      {children}
    </span>
  )
}

export default Badge
