import { useRef, useEffect } from 'react'
import styled from 'styled-components'

import './headerInput.css'
import CloseIcon from 'components/UI/icons/CloseIcon'

const Form = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - 6px);
  padding: 0;
  margin: 3px 0;
  background: var(--color-blue-4);
  border: 1px solid var(--color-gray-1);
  border-radius: var(--radius-sm);
  &::before {
    content: '';
    color: transparent;
    background: linear-gradient(
      90deg,
      transparent,
      var(--color-white),
      var(--color-white)
    );
    width: 60px;
    height: 36px;
    display: block;
    transform: translateX(calc(-100% - 0px));
    position: absolute;
  }
  // SAFARI FIX
  @supports (-webkit-hyphens: none) {
    &::before {
      width: 5px;
      background: var(--color-white);
    }
  }
`
const Input = styled.input`
  display: block;
  flex-basis: 100px;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100px;
  height: 100%;
  color: var(--color-blue-1);
  background: transparent;
  padding: 1px 0 0 6px;
  font-weight: 400;
  font-size: 1.25rem;
  border: none;
  outline: none;
  &::placeholder {
    color: var(--color-gray-2);
    opacity: 0.6;
  }
`

const Button = styled.button`
  background: transparent;
  border: none;
  flex-basis: 20px;
  flex-shrink: 0;
  flex-grow: 0;
`

const HeaderInputField = ({
  value,
  placeholder,
  onChange,
  onClickX,
  onSubmitHandler,
  disabled = false,
  children,
}) => {
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <Form onSubmit={onSubmitHandler}>
      <Input
        ref={inputRef}
        onChange={onChange}
        type="text"
        placeholder={placeholder}
        value={value}
        disabled={disabled}
      ></Input>
      <Button type="button" onClick={onClickX}>
        <CloseIcon
          className="header_input__close_icon icon_hover_effect"
          svgPathClass="header_input__close_icon_color"
          onClick={onClickX}
        />
      </Button>
      {children}
    </Form>
  )
}

export default HeaderInputField
