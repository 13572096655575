import './stars.css'
import StarImg from 'assets/images/star.png'

const Stars = ({ count }) => {
  const stars = []
  for (let i = 0; i < +count; i++) {
    stars.push(<img key={i} className="stars__star" src={StarImg} alt="star" />)
  }

  return <div className="stars__container">{stars}</div>
}

export default Stars
