import FacebookImg from 'assets/images/facebook.svg'
import InstagramImg from 'assets/images/instagram.svg'
import TwitterImg from 'assets/images/twitter.svg'
import WhatsappImg from 'assets/images/whatsapp.svg'
import TiktokImg from 'assets/images/tiktok.svg'

import { URLS } from 'shared/constants'

import Widget, { AppsGroup } from 'components/Widgets/Widget'

const apps = [
  {
    component: FacebookImg,
    alt: 'Logo Facebook',
    url: URLS.FACEBOOK,
    title: 'Obserwuj Lastminutera na Facebooku',
  },
  {
    component: InstagramImg,
    alt: 'Logo Instagram',
    url: URLS.INSTAGRAM,
    title: 'Obserwuj Lastminutera na Instagramie',
  },
  {
    component: TiktokImg,
    alt: 'Logo TikTok',
    url: URLS.TIKTOK,
    title: 'Obserwuj Lastminutera na TikToku',
  },
  {
    component: TwitterImg,
    alt: 'Logo X',
    url: URLS.TWITTER,
    title: 'Obserwuj Lastminutera na X',
  },
  {
    component: WhatsappImg,
    alt: 'Logo WhatsApp',
    url: URLS.WHATSAPP,
    title: 'Obserwuj kanał Lastminuter w WhatsApp',
  },
]

const SocialMedia = () => (
  <AppsGroup apps={apps} imgClassName="apps__social_media">
    Dołącz do grona <br />
    <em>ponad 150 tysięcy osób</em>
    <br /> obserwujących naszą pracę
  </AppsGroup>
)

const SocialMediaWidget = () => (
  <Widget background="dark_blue">
    <SocialMedia />
  </Widget>
)

export { SocialMedia }

export default SocialMediaWidget
