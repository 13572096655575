const DropdownIcon = ({ svgPathClass, ...rest }) => (
  <svg
    width="7"
    height="4"
    viewBox="0 0 7 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      className={svgPathClass}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62667 0.138739C6.81872 0.323724 6.81872 0.623644 6.62667 0.80863L4.0038 3.33495C3.81175 3.51993 3.50037 3.51993 3.30831 3.33495L0.685453 0.80863C0.493399 0.623645 0.493399 0.323724 0.685453 0.138739C0.877508 -0.0462461 1.18889 -0.0462461 1.38094 0.138739L3.65606 2.33011L5.93117 0.138739C6.12323 -0.0462463 6.43461 -0.0462463 6.62667 0.138739Z"
      fill="var(--color-gray-6)"
    />
  </svg>
)

export default DropdownIcon
