import TextInputField from 'components/UI/TextInputField/TextInputField'

const ItemTextInput = ({ data, onClick }) => (
  <li>
    <TextInputField
      label={data.textContent}
      value={data.value}
      onClick={onClick}
    />
  </li>
)

export default ItemTextInput
