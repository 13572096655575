import TripsTable from 'components/TripsTable/TripsTable'
import Deals from 'components/Deals/Deals'

const Page = ({ trips, deals, structure }) => {
  return (
    <>
      {structure.map((i) => {
        if (i.type === 'trips') {
          return (
            <TripsTable
              key={i.id}
              tripsArr={trips.slice(
                i.firstTripIndex,
                i.firstTripIndex + i.tripsCount,
              )}
            />
          )
        } else if (i.type === 'deals') {
          return <Deals key={i.index} data={deals[i.index]} />
        }
        return null
      })}
    </>
  )
}

export default Page
