import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'

import { UserDataProvider } from 'hooks/useUserData'
import { getReferenceDataRequest } from 'api/api'
import { getReferenceDataUrl, getCountryByRegion } from 'index.helpers'
import { registerSW } from 'service/messaging/messaging'
import { IndexedDB } from 'service/indexedDB/indexedDB'
import { pushApiSupported } from 'shared/utils'

import './index.css'
import App from './App'
import ReferenceDataContext from 'context/ReferenceDataContext'
import IndexedDbContext from 'context/IndexedDbContext'

const queryClient = new QueryClient()
queryClient.setDefaultOptions({
  queries: {
    staleTime: Infinity,
  },
})

if (pushApiSupported()) {
  registerSW()
} else {
  console.log('Push API not supported')
}

const db = new IndexedDB()

const Init = () => {
  const [data, setData] = useState()

  useEffect(() => {
    const url = getReferenceDataUrl()
    getReferenceDataRequest(url)
      .then((res) => {
        const referenceData = res.data

        setData({
          referenceData: referenceData,
          countryByRegion: getCountryByRegion(referenceData),
        })
      })
      .catch((e) => {})
  }, [])

  return (
    <>
      {data && (
        <ReferenceDataContext.Provider value={data}>
          <App />
        </ReferenceDataContext.Provider>
      )}
    </>
  )
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <QueryClientProvider client={queryClient}>
    <UserDataProvider>
      <IndexedDbContext.Provider value={db}>
        <Init />
      </IndexedDbContext.Provider>
    </UserDataProvider>
  </QueryClientProvider>,
)
