export const getRedirectUrl = (responseResults, phrase) => {
  if (!responseResults) return null

  let url = null

  for (let item of responseResults) {
    if (item.text.toLowerCase() === phrase) {
      url = item.url
      break
    }
  }

  return url
}
