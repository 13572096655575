import { LOCAL_STORAGE_KEYS as KEYS } from 'shared/constants'
import { INFINITE_PRICE } from 'shared/constants'
import {
  getCookie,
  getLocalStorageData,
  setLocalStorageData,
  setCookie,
} from 'shared/utils'
import { getUserAlerts } from 'api/api'

export const getMinPrice = (trips, minPrice = INFINITE_PRICE) => {
  let min = minPrice
  for (let trip of trips) {
    if (trip.pln < min) min = trip.pln
  }
  return min
}

export const getUrlSearchParams = () => {
  let params = new URLSearchParams(window.location.search)
  let search = params.get('s')
  return search ? { search: search } : {}
}

export const getController = (x) => document.querySelector(`#controller[${x}]`)

export const getRequestContext = () => {
  let contextTxt = getController('context')?.attributes['context']?.textContent
  if (!contextTxt) return {}

  // "{'start': 5, 'offsets': [1, 1, 1, 0]}" => '{"start": 5, "offsets": [1, 1, 1, 0, 0, 0]}'
  contextTxt = contextTxt
    .replaceAll('"', '<')
    .replaceAll("'", '"')
    .replaceAll('<', "'")

  const context = JSON.parse(contextTxt)

  return context ? { context: context } : {}
}

export const parseAlertPriceController = () => {
  try {
    return parseInt(
      getController('alert-price')?.attributes['alert-price']?.textContent,
    )
  } catch (error) {
    return null
  }
}
export const getAlertPrice = (id) =>
  new Promise(async (resolve, reject) => {
    const userData = getLocalStorageData(KEYS.USER_DATA)
    if (!userData || !userData.token) reject('userData not found')

    try {
      const res = await getUserAlerts(userData.token)
      const alert = res.data.alertprices.find((a) => a.id === id)
      if (!alert) {
        console.debug(`Alert id ${id} not found`)
        reject(`Alert id ${id} not found`)
      }

      resolve(alert)
    } catch (e) {
      return reject(e)
    }
  })

// Html may contain Promo div:
// <div id="self-promo" data-campaign="20240610"> which is invisible by default.
// Function checks if this Promo should be visible (depends on whether the given [data-campaign]
// value is not saved in cookie). If so, it adds a close button and displays a Promo.
export const checkPromo = () => {
  const cookieName = 'campaigns'
  const promo = document.getElementById('self-promo')
  const campaign_id = promo?.attributes['data-campaign']?.textContent
  if (!campaign_id) return

  const cookie = getCookie(cookieName)
  let ids = cookie ? cookie.split('|') : []
  if (ids.includes(campaign_id)) return

  // add close button
  const button = document.createElement('button')
  button.className = 'close_button icon_hover_effect cb_in_corner'
  button.innerHTML =
    '<svg width="24" height="24" viewBox="7 7 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" class="close_button__icon"><path class="close_button__icon_color_default" d="M8.46445 15.5355L15.5355 8.46448" stroke="#93b5b0" stroke-width="1.5" stroke-linecap="round"></path><path class="close_button__icon_color_default" d="M8.46446 8.46445L15.5355 15.5355" stroke="#93b5b0" stroke-width="1.5" stroke-linecap="round"></path></svg>'

  button.addEventListener('click', () => {
    promo.style.display = 'none'

    ids.push(campaign_id)
    // Keep max 10 newest ids
    if (ids.length > 10) ids = ids.slice(ids.length - 10)

    setCookie(cookieName, ids.join('|'), 365)
  })

  promo.insertBefore(button, promo.firstChild)
  promo.style.display = 'block'

  // center button when promo is 1 line
  if (promo.clientHeight < 50) button.style = 'top: 15px;'
}

// znak nieprzyczytanych wiadomości na ikonie alertow:
const alertsNumberIcon = document.querySelector('.header__alerts_number')

// "!" bedzie pokazany jeżeli nie ma zapisanego usera oraz okno alertow
// nigdy nie bylo otwarte
export const showOtionallyExclamationMark = () => {
  if (
    !getLocalStorageData(KEYS.USER_DATA) &&
    getLocalStorageData(KEYS.ALERTS_ICON_CLICKED) !== 1
  ) {
    alertsNumberIcon.textContent = '!'
    alertsNumberIcon.style.display = 'flex'
  }
}

// po pokazaniu alertów zapisz do localstorage zeby wiecej nie pokazywac "!"
export const rememberUserVisitedAlertsPanel = () => {
  setLocalStorageData(KEYS.ALERTS_ICON_CLICKED, 1)
  alertsNumberIcon.style.display = 'none'
}
