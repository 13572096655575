import { TripsManager } from 'api/tripsManager'

export const getTags = (alert, referenceData, countryByRegion) => {
  const TM = new TripsManager(referenceData, countryByRegion)
  TM.setFilters(alert.filters_and, alert.filters_not)

  return TM.getFiltersList()
}

export const getAlertDescription = (alert, referenceData, countryByRegion) => {
  const tags = getTags(alert, referenceData, countryByRegion).join(', ')
  return `Wycieczki poniżej ${+alert.price_to + 1} zł: ${tags}`
}
