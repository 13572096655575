import styled from 'styled-components'

import ModalBox from 'components/Layout/ModalBox/ModalBox'

const message = {
  default: 'Wystąpił błąd.',
  'no-trip':
    'Przykro nam, ta oferta już nie jest aktualna. Wierzymy, że znajdziesz jeszcze lepszą :)',
}

const Container = styled.p`
  display: block;
  padding: 2rem;
  margin: 0;
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.4rem;
  background: var(--color-green-5);
  color: var(--color-white);
  border-radius: 15px;
  opacity: 0.8;
`

const ErrorDialog = ({ error, onClose }) => (
  <ModalBox onClose={onClose} variant="centered">
    <Container>{message[error] ?? message.default}</Container>
  </ModalBox>
)

export default ErrorDialog
