import './optionsSelected.css'
import DropdownButton from 'components/UI/buttons/DropdownButton'
import Badge from 'components/UI/Badge/Badge'
import CloseIcon from 'components/UI/icons/CloseIcon'

const OptionsSelected = ({
  placeholder,
  options,
  onClickOption,
  isOpen,
  toggleDropdown,
  optionsSelectedGetter,
}) => {
  const deselectOption = (name) => {
    onClickOption(name, false)
  }

  const handleClickMe = (e) => {
    const l = e.target.localName,
      c = e.target.className
    if (
      !(l === 'path') &&
      !(l === 'svg') &&
      !(l === 'img' && c === 'badge__icon') &&
      !(l === 'img' && c === 'dropdown_icon')
    ) {
      toggleDropdown()
    }
  }

  const Placeholder = () => (
    <span className="options_selected__placeholder">{placeholder}</span>
  )

  const OptionsList = ({ options }) => (
    <ul>
      {options.map((i) => (
        <li key={i.textContent}>
          <Badge style={{ cursor: 'default' }}>
            <CloseIcon
              style={{ width: '0.8rem', height: '0.8rem', cursor: 'pointer' }}
              className="badge__icon"
              svgPathClass="badge__icon_color"
              onClick={() => deselectOption(i.textContent)}
            />
            {i.textDisplay || i.textContent}
          </Badge>
        </li>
      ))}
    </ul>
  )

  const optionsSelected = optionsSelectedGetter(options)

  return (
    <div className="options_selected__field" onClick={handleClickMe}>
      {optionsSelected.length === 0 ? (
        <Placeholder />
      ) : (
        <OptionsList options={optionsSelected} />
      )}
      <DropdownButton isOpen={isOpen} onClick={toggleDropdown} />
    </div>
  )
}

export default OptionsSelected
