import './optionsGroup.css'

const OptionsGroup = ({ name, children }) => (
  <div className="options_group__container">
    {name && <h5 className="options_group__name">{name}</h5>}
    {children}
  </div>
)

export default OptionsGroup
