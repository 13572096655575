import styled from 'styled-components'

const Button = styled.button`
  padding: 0.25rem 0.5rem;
  margin: 1px 0;
  margin-left: ${(props) => (props.ml || '0')};
  height: ${(props) => (props.large ? '2.75rem' : '22px')};
  font-size: 1.2rem;
  font-weight: ${(props) => (props.gray ? '500' : '600')};
  text-align: center;
  color: ${(props) =>
    props.gray ? 'var(--color-gray-3)' : 'var(--color-white)'};
  background: ${(props) =>
    props.disabled
      ? 'var(--color-gray-4)'
      : props.gray
      ? 'var(--color-gray-1)'
      : 'var(--color-blue-1)'};
  border: ${(props) => (props.gray ? '1px solid var(--color-gray-4)' : 'none')};
  border-radius: 0.625rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  transition: opacity 300ms;
  &:hover {
    opacity: 0.8;
  }
`

export default Button
