import { lazy, Suspense } from 'react'
import styled from 'styled-components'

import Spinner from 'components/UI/Spinner/Spinner'
import { P, Container2, Link } from 'components/Alerts/newStyledComponents'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'

const AlertsPanel = lazy(() => import('./AlertsPanel'))

const Fallback = () => {
  return (
    <Container>
      <P>
        Wystąpił błąd. Zamknij okno alertów. <br />
        Zgłoś problem na{' '}
        <Link href="mailto:kontakt@lastminuter.pl">kontakt@lastminuter.pl</Link>
      </P>
    </Container>
  )
}

const AlertsPanelLazy = ({ onClose }) => (
  <Suspense fallback={<Spinner centered />}>
    <ErrorBoundary fallback={<Fallback />}>
      <AlertsPanel closeCallback={onClose} />
    </ErrorBoundary>
  </Suspense>
)

export default AlertsPanelLazy

const Container = styled(Container2)`
  padding-top: 50px;
  padding-bottom: 50px;
`
