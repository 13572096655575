import axios from 'axios'

import { URLS } from 'shared/constants'

export const getReferenceDataRequest = (url) => axios.get(url)

export const getOffers = (data) =>
  axios.post(URLS.OFFERS, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const sendAuthApiRequest = (data) =>
  axios.post(URLS.ALERTS_SUBSCRIBER_VERIFY, data)

export const createAlertPriceRequest = (alertData) =>
  axios.post(URLS.ALERTS_CREATE_PRICE, alertData)

export const createAlertPostRequest = (alertData) =>
  axios.post(URLS.ALERTS_CREATE_POST, alertData)

export const deleteAlertPriceRequest = (id, token) =>
  axios.delete(URLS.ALERTS_DELETE_PRICE.replace('{alert_id}', id), {
    data: {
      token: token,
    },
  })

export const deleteAlertPostRequest = (id, token) =>
  axios.delete(URLS.ALERTS_DELETE_POST.replace('{alert_id}', id), {
    data: {
      token: token,
    },
  })

export const updateAlertPriceRequest = (id, alertData) =>
  axios.put(URLS.ALERTS_UPDATE_PRICE.replace('{alert_id}', id), alertData)

export const sendUserPreferences = (data) =>
  axios.put(URLS.SEND_USER_PREFS.replace('{subscriber_id}', data.id), data)

export const updateToken = (subscriberId, prevToken, data) => {
  const url = URLS.ALERTS_UPDATE_TOKEN.replace(
    '{subscriber_id}',
    subscriberId,
  ).replace('{prevToken}', prevToken)

  return axios.put(url, data)
}

export const pushTestMessage = (token) =>
  axios.post(URLS.ALERTS_PUSH_ME, { token: token, transport: 'browser' })

export const deleteUser = (id, data) =>
  axios.delete(URLS.SEND_USER_PREFS.replace('{subscriber_id}', id), {
    data: data,
  })

export const getUserAlerts = (token) =>
  axios.get(URLS.ALERTS_GET.replace('{token}', token))

export const postAlertsCrash = (payload) =>
  axios.post(URLS.ALERTS_CRASH, payload)

export const getSearchResults = (phrase) =>
  axios.get(URLS.SEARCH.replace('{phrase}', phrase))

export const shareContext = (data) =>
  axios.post(URLS.SHARE_CONTEXT, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
