import { decode } from 'html-entities'

import { URLS } from 'shared/constants'
import { formatPrice } from 'shared/utils'

import './deals.css'
import Badge from 'components/UI/Badge/Badge'

const Deals = ({ data }) => {
  if (!data) return

  return (
    <div className="deals__container">
      <div className="deals__header">
        <h2 className="deals__title">
          <a href={data.link}>{decode(data.title)}</a>
        </h2>
        <span className="deals__price">{formatPrice(data.cena)}</span>
      </div>
      <div className="deals__tags">
        <ul>
          {data.tags.map((t) => (
            <li key={t.name}>
              <a href={`${URLS.TRIPS}${t.slug}/`}>
                <Badge icon={t.icon}>{t.name}</Badge>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="deals__text">
        <a
          href={data.link}
          dangerouslySetInnerHTML={{ __html: data.excerpt }}
        ></a>
      </div>
      <DealsFooter
        dateAgo={data.date_ago}
        dateGmt={data.date}
        expired={data.expired}
      />
    </div>
  )
}
export default Deals

export const DealsFooter = ({ dateGmt, dateAgo, expired, largeMargin }) => {
  let className = 'deals__footer'
  if (largeMargin) className += ' deals__footer_lm'

  return (
    <p className={className}>
      <span className="deals__time" title={dateGmt}>
        {dateAgo}
      </span>
      {expired && <span className="deals__valid_until">Nieaktualne</span>}
    </p>
  )
}
