export const META = {
  // after how many days Firebase Messaging token will be refreshed
  REFRESH_TOKEN_AFTER: 30,
}

export const URLS = {
  REFERENCE_DATA:
    process.env.NODE_ENV === 'production'
      ? 'https://lastminuter.pl/api/referencedata.json'
      : 'http://localhost:3030/api/referencedata.json',
  OFFERS:
    process.env.NODE_ENV === 'production'
      ? '/offers/'
      : 'https://api.lastminuter.pl/offers/',
  TRIPS: '/wycieczki/',
  HITS: '/nasze-hity/',
  IOS: '/aplikacja-ios',
  ANDROID: '/aplikacja-android',
  INSTAGRAM: 'https://www.instagram.com/lastminuterpl/',
  FACEBOOK: 'https://pl-pl.facebook.com/Lastminuter/',
  TWITTER: 'https://twitter.com/lastminuterpl',
  WHATSAPP: 'https://whatsapp.com/channel/0029VaE65EhI1rcbgQtH0m1K',
  TIKTOK: 'https://www.tiktok.com/@lastminuterpl',
  EMAIL: 'kontakt@lastminuter.pl',
  RSS: 'https://www.lastminuter.pl/feed/',
  ALERTS_TERMS_AND_CONDITIONS: '/regulamin-lastminuter.pdf?2023',
  ALERTS_SUBSCRIBER_VERIFY:
    process.env.NODE_ENV === 'production'
      ? '/api/alert/subscribers/'
      : 'http://localhost:3030/api/alert/subscribers/',
  ALERTS_CREATE_PRICE:
    process.env.NODE_ENV === 'production'
      ? '/api/alert/alertprice/'
      : 'http://localhost:3030/api/alert/alertprice/',
  ALERTS_CREATE_POST:
    process.env.NODE_ENV === 'production'
      ? '/api/alert/alertpost/'
      : 'http://localhost:3030/api/alert/alertpost/',
  SEND_USER_PREFS:
    process.env.NODE_ENV === 'production'
      ? '/api/alert/subscriber/{subscriber_id}/'
      : 'http://localhost:3030/api/alert/subscriber/{subscriber_id}/',
  ALERTS_UPDATE_TOKEN:
    process.env.NODE_ENV === 'production'
      ? '/api/alert/subscriber/{subscriber_id}/?token={prevToken}'
      : 'http://localhost:3030/api/alert/subscriber/{subscriber_id}/?token={prevToken}',
  ALERTS_PUSH_ME:
    process.env.NODE_ENV === 'production'
      ? '/api/alert/push/me/'
      : 'http://localhost:3030/api/alert/push/me/',
  ALERTS_GET:
    process.env.NODE_ENV === 'production'
      ? '/api/alert/alerts/?token={token}'
      : 'http://localhost:3030/api/alert/alerts/?token={token}',
  ALERTS_DELETE_PRICE:
    process.env.NODE_ENV === 'production'
      ? '/api/alert/alertprice/{alert_id}/'
      : 'http://localhost:3030/api/alert/alertprice/{alert_id}/',
  ALERTS_DELETE_POST:
    process.env.NODE_ENV === 'production'
      ? '/api/alert/alertpost/{alert_id}/'
      : 'http://localhost:3030/api/alert/alertpost/{alert_id}/',
  ALERTS_UPDATE_PRICE:
    process.env.NODE_ENV === 'production'
      ? '/api/alert/alertprice/{alert_id}/'
      : 'http://localhost:3030/api/alert/alertprice/{alert_id}/',
  ALERTS_CRASH:
    process.env.NODE_ENV === 'production'
      ? '/api/alert/crash/'
      : 'http://localhost:3030/api/alert/crash/',
  SEARCH:
    process.env.NODE_ENV === 'production'
      ? '/autocomplete/{phrase}/'
      : 'http://localhost:3030/api/autocomplete/{phrase}/',
  SHARE_CONTEXT:
    process.env.NODE_ENV === 'production'
      ? '/sharecontext/'
      : 'http://localhost:3030/sharecontext/',
}

export const LOCAL_STORAGE_KEYS = {
  USER_DATA: 'ud',
  USER_EMAIL: 'e',
  FILTERS: 'f',
  COOKIES_ACCEPTED: 'ca',
  NOTIFICATIONS: 'n',
  NOTIFICATIONS_CONFIRM: 'nc',
  SOURCES: 's',
  TOKEN_TS: 'ts',
  ALERTS_ICON_CLICKED: 'a',
}

export const TRANSPORT = {
  MAIL: 'mail',
  DEFAULT: 'mail',
  BROWSER: 'browser',
}

export const STATUS = {
  INSTALLED: 'installed',
  UNINSTALLED: 'uninstalled',
}

export const DAYS = ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So']

export const MONTHS = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
]

export const INFINITE_PRICE = 999999

export const shorten = {
  Warszawa: 'Warsz.',
  Wrocław: 'Wroc.',
  Katowice: 'Katow.',
  Kraków: 'Kraków',
  Poznań: 'Poznań',
  Gdańsk: 'Gdańsk',
  Berlin: 'Berlin',
  Szczecin: 'Szcz.',
  Rzeszów: 'Rzesz.',
  Łódź: 'Łódź',
  Lublin: 'Lublin',
  Bydgoszcz: 'Bydg.',
  'All Inclusive': 'All inc.',
  'Trzy posiłki': '3 posiłki',
  'Dwa posiłki': '2 posiłki',
  Śniadania: 'Śniadania',
  Własne: 'Własne',
}
