import DropdownIcon from 'components/UI/icons/DropdownIcon'
import './dropdownButton.css'

const DropdownButton = ({ isOpen, onClick }) => (
  <button className="dropdown_button">
    <DropdownIcon
      style={{
        transform: `rotate(${isOpen ? 180 : 0}deg)`,
      }}
      className="dropdown_icon"
      onClick={onClick}
    />
  </button>
)

export default DropdownButton
