import './searchTripButtons.css'

import TrashIcon from 'components/UI/icons/TrashIcon'

const TrashButton = ({ onClick }) => (
  <TrashIcon
    className="search_trip__trash_icon icon_hover_effect"
    svgPathClass="stt_icon_color"
    onClick={onClick}
  />
)

export default TrashButton
