import { useOutsideClick } from 'hooks/useOutsideClick'

import './optionsDropdown.css'
import CloseButton from 'components/UI/buttons/CloseButton'

const OptionsDropdown = ({
  closeDropdown,
  limitDropdownHeight,
  positionFixed,
  size, //null | "sm" | "lg"
  children,
}) => {
  const dropdownRef = useOutsideClick(closeDropdown)

  const onClickMe = (e) => {
    const l = e.target.localName,
      c = e.target.className
    if (
      (l === 'div' && c === 'dropdown') ||
      (l === 'div' && c === 'options_group__container') ||
      (l === 'p' && c === 'options_group__name')
    )
      closeDropdown()
  }

  let styleDropdown = null
  if (limitDropdownHeight) {
    styleDropdown = {
      maxHeight: 'var(--dropdown-max-height)',
      overflow: 'hidden scroll',
    }
  }

  let className = 'dropdown custom_scrollbar'
  if (positionFixed) className += ' dropdown_fixed'
  if (size === 'sm') className += ' dropdown_sm'
  if (size === 'lg') className += ' dropdown_lg'

  return (
    <div
      ref={dropdownRef}
      style={styleDropdown}
      className={className}
      onClick={onClickMe}
    >
      <CloseButton onClick={closeDropdown} color="var(--color-gray-6)" />
      {children}
    </div>
  )
}

export default OptionsDropdown
