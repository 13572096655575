import './box.css'

const Box = ({ children, classes, ...rest }) => {
  let classNames = 'box'
  if (classes) classNames += ` ${classes}`

  return (
    <section className={classNames} {...rest}>
      {children}
    </section>
  )
}

export default Box
