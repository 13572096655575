import { useOutsideClick } from 'hooks/useOutsideClick'

import './modalBox.css'
import Modal from 'components/UI/Modal/Modal'
import CloseButton from 'components/UI/buttons/CloseButton'

const ModalBox = ({
  variant = 'dropdown',
  iconId, // id objektu DOM ikony otwierającej dropdown
  onClose,
  offsetRight,
  children,
}) => {
  const dropdownRef = useOutsideClick(onClose, iconId)

  let className = 'modal_dropdown custom_scrollbar',
    closeButtonColor = null,
    closeButtonAbove = false

  if (offsetRight) className += ' md_rigth_offset'

  if (variant === 'centered') {
    className = 'modal_centered_box custom_scrollbar'
    closeButtonColor = 'white'
    closeButtonAbove = true
  }

  return (
    <Modal>
      <div ref={dropdownRef} className={className}>
        <CloseButton
          onClick={onClose}
          color={closeButtonColor}
          above={closeButtonAbove}
        />
        {children}
      </div>
    </Modal>
  )
}

export default ModalBox
