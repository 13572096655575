import { OTHER_OPTIONS_LABEL } from 'components/SearchTrip/SearchTrip.constants'

import OptionsGroup from 'components/UI/OptionsGroup/OptionsGroup'
import ItemCheckbox from 'components/SearchTrip/ItemsComponents/ItemCheckbox/ItemCheckbox'
import ItemTextInput from 'components/SearchTrip/ItemsComponents/ItemTextInput/ItemTextInput'

const OtherOptions = ({ options, onClickOption }) => {
  return (
    <>
      {options.map((group) => (
        <OptionsGroup
          key={group.textContent}
          name={OTHER_OPTIONS_LABEL[group.textContent]}
        >
          <ul>
            {group['items'].map((i) => {
              return i.isTextField ? (
                <ItemTextInput
                  key={i.textContent}
                  data={i}
                  onClick={onClickOption}
                />
              ) : (
                <ItemCheckbox
                  key={i.textContent}
                  data={i}
                  onClick={onClickOption}
                  addLi
                />
              )
            })}
          </ul>
        </OptionsGroup>
      ))}
    </>
  )
}

export default OtherOptions
