class IndexedDB {
  constructor() {
    this.dbName = 'lastminuter-database'
    this.storeName = 'lastminuter-messages'
    this.messagesKey = 'messages'
    this.initialized = false
    this.initPromise = this.init()
  }

  async init() {
    this.db = await this.openDatabase()
    this.initialized = true
  }

  ready = () => this.initPromise

  openDatabase() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1)

      request.onupgradeneeded = (event) => {
        const db = event.target.result
        db.createObjectStore(this.storeName, { keyPath: 'id' })
      }

      request.onsuccess = (event) => resolve(event.target.result)
      request.onerror = (event) => reject(event.target.error)
    })
  }

  readStored() {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly')
      const store = transaction.objectStore(this.storeName)
      const request = store.get(this.messagesKey)

      request.onsuccess = (event) =>
        resolve(event.target.result ? event.target.result.value : [])
      request.onerror = (event) => reject(event.target.error)
    })
  }

  writeStored(array) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite')
      const store = transaction.objectStore(this.storeName)
      const request = store.put({ id: this.messagesKey, value: array })

      request.onsuccess = (event) => resolve()
      request.onerror = (event) => reject(event.target.error)
    })
  }

  async addToStored(notificationData) {
    let nots = await this.readStored()

    if (nots.some((e) => e.data.id === notificationData.id)) {
      // Notification already exists
      return
    }

    nots.push({
      data: notificationData,
      ts: new Date().getTime(),
    })

    // Keep max 10 newest notifications
    if (nots.length > 10) nots = nots.slice(nots.length - 10)

    await this.writeStored(nots)
  }

  clear() {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite')
      const store = transaction.objectStore(this.storeName)
      const request = store.clear()

      request.onsuccess = (event) => resolve()
      request.onerror = (event) => reject(event.target.error)
    })
  }
}

export { IndexedDB }
