import styled from 'styled-components'

const Button = styled.button`
  font-size: 1.1rem;
  font-weight: 400;
  color: ${(props) =>
    props.disabled ? 'var(--color-blue-2)' : 'var(--color-blue-3)'};
  margin: 0;
  padding: 0;
  background: transparent;
  text-align: ${(props) => (props.alignLeft ? 'left' : 'center')};
  border: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  &:hover {
    text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
  }
`

const ButtonAsA = ({ label, onClick, disabled, alignLeft }) => (
  <Button onClick={onClick} disabled={disabled} alignLeft={alignLeft}>
    {label}
  </Button>
)

export default ButtonAsA
