import { initializeApp } from 'firebase/app'

import { getMessaging, getToken } from 'firebase/messaging'

import { META, TRANSPORT, LOCAL_STORAGE_KEYS as KEYS } from 'shared/constants'
import {
  getLocalStorageData,
  setLocalStorageData,
  getDaysSinceNow,
  sleep,
} from 'shared/utils'

import { updateToken } from 'api/api'
import { IndexedDB } from 'service/indexedDB/indexedDB'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}

const app = initializeApp(firebaseConfig)

let messaging;
try {
  messaging = getMessaging(app)
} catch (error) {}

const db = new IndexedDB()

const generateToken = () =>
  Promise.race([
    new Promise(async (resolve, reject) => {
      if (messaging) {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        })
        resolve(token)
      }
    }),
    sleep(10 * 1000), // timeout
  ])

const registerSW = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js', {
        scope: '/firebase-cloud-messaging-push-scope',
        type: 'classic',
      })
      .then((registration) => {
        console.debug('Service Worker registered')
      })
      .catch((error) => {
        console.error('Error registering service worker:', error)
      })
  }
}

const startFirebaseMessagingSWListener = (callback) => {
  console.log('initialize service worker listener')
  if (navigator && navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('message', async (event) => {
      await processNotification(event.data)
      callback()
    })
  }
}

const processNotification = async (payload) => {
  try {
    // parsuj
    const parsed = parseNotification(payload)

    // zapisz do local storage
    await db.addToStored(parsed)

    // zmien title
    await updatePageTitleAndAlertsIcon()
  } catch (error) {
    console.error(error)
  }
}

// zwraca liczbe nieprzeczytanych wiadomości
const getUnreadMessagesCount = async () => {
  return await db
    .readStored()
    .then((messages) => messages.filter((e) => e.read !== true).length)
    .catch(() => 0)
}

// formatuje liczbe nieprzeczytanych wiadomości,
// gdy wiecej niż 9 to zwraca "9+", jak nie ma to zwraca ""
const getUnreadMessagesText = (n) => {
  if (n === 0) return ''
  if (n > 9) return '9+'
  return +n
}

const updatePageTitleAndAlertsIcon = async () => {
  await db.ready()
  const unreadCount = await getUnreadMessagesCount()

  // title
  const title = document.querySelector('title')

  let text = title.textContent
  // usuń oznaczenie (x) jeśli jest
  if (text.startsWith('(')) {
    const x = text.indexOf(')')
    text = text.substring(x + 1)
  }
  if (unreadCount > 0) text = `(${unreadCount}) ${text}`
  title.textContent = text

  // ikona powiadomień w header
  const alertsNumberIcon = document.querySelector('.header__alerts_number')

  if (unreadCount === 0) {
    alertsNumberIcon.style.display = 'none'
  } else {
    alertsNumberIcon.textContent = getUnreadMessagesText(unreadCount)
    alertsNumberIcon.style.display = 'flex'
  }
}

// zwraca obiekt:
// { ver: ('console'|'v1'|'v2'), title, rdir }
const parseNotification = (payload) => {
  const data = payload.data

  if (data?.version === '1') {
    // może być podane albo "rdir" albo "post_id"
    //{"post_id":"109083","version":"1","title":"Grecja za 1440 zł!"}
    //{"rdir":"http://lastminuter...","version":"1","title":"Grecja za 1440 zł!"}
    var title = data?.title
    var rdir = data?.rdir
    if (!rdir && data.post_id) {
      rdir = `/okazje/${data.post_id}`
    }

    if (!title || !rdir) {
      console.debug('brak pełnych danych', title, rdir)
      throw Error('Notification not supported')
    }

    return {
      ver: 'v1',
      rdir: rdir,
      title: title,
      id: data.id,
    }
  } else if (data?.version === '2') {
    //{"alert_id":"34010","version":"2","alert_type":"alertprice","alert_filters_not":"","alert_filters_and":"10:Warszawa|3:Turcja","title":"ALERT! Turcja za 1442 zł."}
    // eslint-disable-next-line
    var title = data?.title
    var alertId = data?.alert_id

    if (!title || !alertId) {
      console.debug('brak pełnych danych', title, alertId)
      throw Error('Notification not supported')
    }

    return {
      ver: 'v2',
      rdir: `/?alertprice=${alertId}`,
      title: title,
      id: data.id,
    }
  }

  throw Error('Notification not supported')
}

// checks if the firebase messaging token needs to be renewed
// and executes it if necessary
const checkTokenNeedToBeRenewed = async () => {
  const userData = getLocalStorageData(KEYS.USER_DATA)
  const browserNotifications = userData?.transport === TRANSPORT.BROWSER

  // return if user is not subscribed or doesn't have web notifications
  if (!userData || !browserNotifications) return

  const currentToken = userData.token
  const subscriberId = userData.id
  const tokenTs = getLocalStorageData(KEYS.TOKEN_TS)

  if (!currentToken || !subscriberId) return

  const tokenTimeAgo =
    tokenTs !== null ? getDaysSinceNow(new Date(tokenTs)) : null

  if (tokenTimeAgo === null || tokenTimeAgo > META.REFRESH_TOKEN_AFTER) {
    console.debug('token renewal')

    try {
      if (Notification.permission !== 'granted') {
        const permission = await Notification.requestPermission()

        if (permission === 'denied') {
          console.warn('token renewal interrupted (notifications are blocked)')
        }
      }

      if (Notification.permission === 'granted') {
        const messagingToken = await generateToken()

        await updateToken(subscriberId, currentToken, {
          token: messagingToken,
          transport: TRANSPORT.BROWSER,
        }).then((res) => {
          if (res.status === 200 && res.data.id) {
            setLocalStorageData(KEYS.USER_DATA, {
              ...userData,
              token: messagingToken,
            })
            setLocalStorageData(KEYS.TOKEN_TS, new Date().getTime())
          } else {
            throw Error('Invalid response to token renewal request')
          }
        })
      }
    } catch (error) {
      console.error(error)
    }
  }
}

export {
  generateToken,
  registerSW,
  startFirebaseMessagingSWListener,
  updatePageTitleAndAlertsIcon,
  checkTokenNeedToBeRenewed,
  getUnreadMessagesCount,
}
