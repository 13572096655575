import './Spinner.css'

const Spinner = ({ centered, small }) => {
  let className = 'lds_ring'
  if (small) className += ' ring_sm'

  const Ring = () => (
    <div className={className}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )

  return centered ? (
    <div className="lds_ring__container">
      <Ring />
    </div>
  ) : (
    <Ring />
  )
}

export default Spinner
