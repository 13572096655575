import Portal from 'components/portals/Portal'
import TripsTable from 'components/TripsTable/TripsTable'

const TripsTablePortal = ({ tripsArr, isLoading, id }) => (
  <Portal
    elementId={id}
    component={<TripsTable tripsArr={tripsArr} isLoading={isLoading} />}
  />
)

export default TripsTablePortal
