import { useState } from 'react'
import DatePicker from 'react-datepicker'

import { formatDateDDMMYYYY, formatDateYYYYMMDD } from 'shared/utils'
import { locale } from './DateOpions.helpers'

import OptionsGroup from 'components/UI/OptionsGroup/OptionsGroup'
import RemoveIcon from 'components/UI/icons/RemoveIcon'

import 'react-datepicker/dist/react-datepicker.css'
import './dateOptions.css'

const DateOptions = ({ options, onClickOption }) => {
  const { textContent: labelStart, value: initValueStart } = options[0].items[0]
  const { textContent: labelEnd, value: initValueEnd } = options[1].items[0]

  const [startDate, setStartDate] = useState(
    initValueStart ? new Date(formatDateYYYYMMDD(initValueStart)) : null,
  )
  const [endDate, setEndDate] = useState(
    initValueEnd ? new Date(formatDateYYYYMMDD(initValueEnd)) : null,
  )

  const handleChangeDate = (date, label) => {
    if (!date) {
      onClickOption(label, '')
      label === labelStart ? setStartDate(date) : setEndDate(date)
      return
    }

    if (label === labelStart) {
      if (endDate && date - endDate >= 0) {
        // gdy data końcowa jest wcześniejsza niż nowa startDate to wyczyść endDate
        onClickOption(labelEnd, '')
        setEndDate(null)
      }

      setStartDate(date)
      onClickOption(labelStart, formatDateDDMMYYYY(date))
      return
    }

    if (label === labelEnd) {
      if (date - startDate === 0) {
        // gdy startDate == endDate to wyczyść endDate
        onClickOption(labelEnd, '')
        setEndDate(null)
        return
      }

      setEndDate(date)
      onClickOption(labelEnd, formatDateDDMMYYYY(date))
    }
  }

  const DatePickerComponent = ({ ...rest }) => (
    <DatePicker
      inline
      calendarClassName="calendar"
      locale={locale}
      calendarStartDay={1}
      {...rest}
    />
  )

  return (
    <OptionsGroup>
      <div className="date_options__container">
        <DateItem
          label={labelStart}
          date={startDate}
          clearDate={() => handleChangeDate(null, labelStart)}
        >
          <DatePickerComponent
            selected={startDate}
            onChange={(startDate) => handleChangeDate(startDate, labelStart)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            minDate={new Date()}
          />
        </DateItem>
        <DateItem
          label={labelEnd}
          date={endDate}
          clearDate={() => handleChangeDate(null, labelEnd)}
        >
          <DatePickerComponent
            selected={endDate}
            onChange={(endDate) => handleChangeDate(endDate, labelEnd)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </DateItem>
      </div>
    </OptionsGroup>
  )
}

const DateItem = ({ label, date, clearDate, children }) => (
  <div className="date_options__item">
    <div className="date_options__input_container">
      <label className="date_options__input_label">{label}</label>
      <input
        className="date_options__input"
        type="text"
        value={date ? formatDateDDMMYYYY(date) : ''}
        disabled
      />
      {date && (
        <RemoveIcon className="date_options__remove_icon" onClick={clearDate} />
      )}
    </div>
    {children}
  </div>
)

export default DateOptions
