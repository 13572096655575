// Returns url of referencedata.json, which should be provided as attribute in index.html:
// <div id="controller" style="display: none" ref-data="/referencedata.json"></div>
export const getReferenceDataUrl = () =>
  document.querySelector('#controller[ref-data]')?.attributes['ref-data']
    ?.textContent

export const getCountryByRegion = (referenceData) => {
  const res = {}
  for (let group of referenceData.destgroups) {
    for (let country of referenceData[group]) {
      for (let region of referenceData[country]) {
        res[region] = country
      }
    }
  }

  return res
}
