import OptionsGroup from 'components/UI/OptionsGroup/OptionsGroup'
import ItemCheckbox from 'components/SearchTrip/ItemsComponents/ItemCheckbox/ItemCheckbox'

const SourceOptions = ({ options, onClickOption }) => {
  return (
    <OptionsGroup>
      <ul>
        {options[0].items.map((i) => (
          <ItemCheckbox
            key={i.textContent}
            data={i}
            onClick={onClickOption}
            addLi
          />
        ))}
      </ul>
    </OptionsGroup>
  )
}

export default SourceOptions
