import { useEffect, useCallback, useMemo } from 'react'

// Some components should be minimized on mobile until user clicks on it.
// This hook adds appriopriate click events as well as callbacks if needed by components.
const useMinimizedComponents = () => {
    const searchTrip = useMemo(() => document.getElementById("search_trip"), []);
    const maximizeSearchTrip = useCallback(() => {
        if (!searchTrip) return

        searchTrip.classList.remove("stc_minimized")
    }, [searchTrip]);

    useEffect(() => {
        if (!searchTrip) return

        searchTrip.addEventListener('click', maximizeSearchTrip, true)

        return () => {
            document.removeEventListener('click', maximizeSearchTrip, true)
        }
    }, [searchTrip, maximizeSearchTrip])

    const pricesInfo = useMemo(() => document.querySelector(".thpic_minimized"), []);
    const maximizePricesInfo = useCallback(() => {
        if (!pricesInfo) return

        pricesInfo.classList.remove("thpic_minimized")
        pricesInfo.firstElementChild.classList.remove("thpi_minimized")
    }, [pricesInfo]);

    useEffect(() => {
        if (!pricesInfo) return

        pricesInfo.addEventListener('click', maximizePricesInfo, true)

        return () => {
            document.removeEventListener('click', maximizePricesInfo, true)
        }
    }, [pricesInfo, maximizePricesInfo])

    return { maximizeSearchTrip: maximizeSearchTrip }
}

export { useMinimizedComponents }
