import { useCallback } from 'react'

import { TYPES, CAPTIONS } from 'components/SearchTrip/SearchTrip.constants'
import { GETTERS } from 'components/SearchTrip/SearchTrip.helpers'

import './selectComponent.css'
import OptionsSelected from 'components/SearchTrip/OptionsSelected/OptionsSelected'
import OptionsDropdown from 'components/SearchTrip/OptionsDropdown/OptionsDropdown'
import TrashIcon from 'components/UI/icons/TrashIcon'

const SelectComponent = ({
  options,
  type,
  onClickOption,
  isDropdownOpen,
  switchDropdowns,
  resetOptions,
  limitDropdownHeight,
  optionsComponent,
  size,
}) => {
  const toggleDropdown = () => {
    switchDropdowns(isDropdownOpen ? '' : type)
  }

  const closeDropdown = useCallback(
    (event) => {
      switchDropdowns('')
      event && event.stopPropagation()
    },
    [switchDropdowns],
  )

  const resetOptionsHandler = () => {
    resetOptions([type])
  }

  const ResetButton = () => {
    const selectedOptions = GETTERS[TYPES[type]](options)

    let className = 'select_component__trash_icon icon_hover_effect'
    if (selectedOptions.length === 0) className += ' trash_icon_disabled'

    return (
      <TrashIcon
        onClick={resetOptionsHandler}
        className={className}
        svgPathClass="trash_icon__color"
      />
    )
  }

  const isTypeAlert =
    [TYPES.ALERT_FROM, TYPES.ALERT_SEASON, TYPES.ALERT_TO].indexOf(type) !== -1

  return (
    <>
      <OptionsSelected
        placeholder={CAPTIONS[type].placeholder}
        options={options}
        onClickOption={onClickOption}
        isOpen={isDropdownOpen}
        toggleDropdown={toggleDropdown}
        optionsSelectedGetter={GETTERS[TYPES[type]]}
      />
      {isDropdownOpen && (
        <OptionsDropdown
          closeDropdown={closeDropdown}
          limitDropdownHeight={limitDropdownHeight}
          positionFixed={isTypeAlert}
          size={size}
        >
          <ResetButton />
          {optionsComponent}
        </OptionsDropdown>
      )}
    </>
  )
}

export default SelectComponent
