import styled from 'styled-components'

import ButtonAsA from 'components/UI/buttons/ButtonAsA'
import Spinner from 'components/UI/Spinner/Spinner'

const ButtonContainter = styled.div`
  margin: 1rem;
  text-align: center;
`

const ExtraPages = ({ pages, isLoading, loadMore, loadAnotherPage }) => {
  const ShowMore = () => (
    <ButtonContainter>
      <ButtonAsA
        label="Pokaż więcej &raquo;"
        onClick={loadAnotherPage}
        disabled={!loadMore}
      />
    </ButtonContainter>
  )

  return (
    <>
      {pages.map((p) => p)}
      {isLoading ? <Spinner centered /> : <ShowMore />}
    </>
  )
}

export default ExtraPages
