import { useState, useRef } from 'react'

import HeaderInputField from 'components/UI/HeaderInputField/HeaderInputField'
import Modal from 'components/UI/Modal/Modal'
import SearchResults from 'components/Search/SearchResults'

const KEYSTROKE_WAIT_TIME = 500 // the request is sent if no key has been pressed during this time

const Search = ({ onClose }) => {
  const [inputValue, setInputValue] = useState('')
  const [searchPhrase, setSearchPhrase] = useState('')
  const lastKeystrokeRef = useRef()
  const redirectToRef = useRef()

  const onPressEnter = (e) => {
    e.preventDefault()

    if (!redirectToRef.current) return

    window.location.href = redirectToRef.current
  }

  const onKeystroke = (e) => {
    e.preventDefault()

    let value = e.target.value
    if (value === undefined) return

    setInputValue(value)
    redirectToRef.current = null

    lastKeystrokeRef.current = new Date()
    setTimeout(() => {
      if (new Date() - lastKeystrokeRef.current > KEYSTROKE_WAIT_TIME - 50) {
        setSearchPhrase(value.trim())
      }
    }, KEYSTROKE_WAIT_TIME)
  }

  return (
    <>
      <HeaderInputField
        value={inputValue}
        placeholder="Wyszukaj"
        onChange={onKeystroke}
        onClickX={onClose}
        onSubmitHandler={onPressEnter}
      />
      {searchPhrase.trim() === '' ? (
        <Modal />
      ) : (
        <SearchResults phrase={searchPhrase} redirectToRef={redirectToRef} />
      )}
    </>
  )
}

export default Search
