import { DAYS, MONTHS } from 'shared/constants'

export const locale = {
  localize: {
    day: (n) => DAYS[n],
    month: (n) => MONTHS[n],
  },
  formatLong: {
    date: () => 'dd/mm/yyyy',
  },
}
