import IconLocation from 'assets/images/location.svg'
import IconClose from 'assets/images/cross.svg'
import IconClock from 'assets/images/clock.svg'
import IconCalendar from 'assets/images/calendar.svg'
import IconPalette from 'assets/images/palette.svg'
import IconPlane from 'assets/images/plane.svg'

export const ICONS = {
  CLOSE: 'close',
  LOCATION: 'location',
  BOARDING: 'boarding',
  CALENDAR: 'calendar',
  CLOCK: 'clock',
  SOURCE: 'source',
}

export const icons = {
  [ICONS.CLOSE]: { component: IconClose, alt: 'close icon' },
  [ICONS.LOCATION]: { component: IconLocation, alt: 'location icon' },
  [ICONS.BOARDING]: { component: IconPalette, alt: 'palette icon' },
  [ICONS.CALENDAR]: { component: IconCalendar, alt: 'calendar icon' },
  [ICONS.CLOCK]: { component: IconClock, alt: 'clock icon' },
  [ICONS.SOURCE]: { component: IconPlane, alt: 'plane icon' },
}
