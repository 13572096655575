import './widget.css'

const Widget = ({ background, children }) => (
  <div className={'widget widget_bg_' + background}>{children}</div>
)

export const AppsGroup = ({ apps, imgClassName, children, ...rest }) => (
  <div className="apps_group" {...rest}>
    <h6 className="apps_group__title">{children}</h6>
    <ul>
      {apps.map((app) => (
        <GroupItem key={app.title} data={app} imgClassName={imgClassName} />
      ))}
    </ul>
  </div>
)

export const GroupItem = ({ data, imgClassName }) => (
  <li className="apps_group__item">
    <a
      href={data.url}
      target="_blank"
      rel="nofollow noreferrer"
      title={data.title}
    >
      <img className={imgClassName} src={data.component} alt={data.alt}></img>
    </a>
  </li>
)

export const SingleAppWidget = ({ data, imgClassName }) => (
  <div className="apps_group__item">
    <a
      href={data.url}
      target="_blank"
      rel="nofollow noreferrer"
      title={data.title}
    >
      <img className={imgClassName} src={data.component} alt={data.alt}></img>
    </a>
  </div>
)

export default Widget
