import React from 'react'

import { postAlertsCrash } from 'api/api'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    const e = {}
    for (var name of Object.getOwnPropertyNames(error)) {
      e[name] = error[name]
    }

    postAlertsCrash({
      error: e,
      info: info,
    }).catch(console.error)
  }

  render() {
    return this.state.hasError ? this.props.fallback : this.props.children
  }
}

export default ErrorBoundary
