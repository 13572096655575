import ErrorDialog from 'components/ErrorDialog/ErrorDialog'
import Portal from 'components/portals/Portal'

const ErrorDialogPortal = ({ error, onClose }) => (
  <Portal
    elementId="portal-error-dialog"
    component={<ErrorDialog error={error} onClose={onClose} />}
  />
)

export default ErrorDialogPortal
