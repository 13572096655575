import { useState, useEffect } from 'react'

const TextInputField = ({ label, value: initValue, onClick }) => {
  const [value, setValue] = useState(initValue)

  useEffect(() => {
    setValue(initValue)
  }, [initValue])

  const handleChange = (e) => {
    const value = e.target.value
    if (value === '' || /^\d+$/.test(value)) {
      onClick(label, value)
      setValue(value)
    }
  }

  return (
    <div className="text_input__field">
      <span className="text_input__label">{label}</span>
      <input
        className="text_input__input"
        type="text"
        value={value}
        onChange={handleChange}
      />
      <span className="text_input__label">zł</span>
    </div>
  )
}
export default TextInputField
