import CloseIcon from 'components/UI/icons/CloseIcon'
import './closeButton.css'

const CloseButton = ({ onClick, color, above, ...rest }) => {
  let className = 'close_button icon_hover_effect cb_in_corner',
    svgPathClass = 'close_button__icon_color_default'

  if (above) className = 'close_button icon_hover_effect cb_above'

  if (color === 'white') svgPathClass = 'close_button__icon_color_white'

  return (
    <button className={className} {...rest}>
      <CloseIcon
        className="close_button__icon"
        svgPathClass={svgPathClass}
        onClick={onClick}
      />
    </button>
  )
}

export default CloseButton
